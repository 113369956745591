/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:50:15
 * @modify date 2022-10-18 09:50:15
 * @desc [description]
 */

import i18next from "i18next";
import moment from "moment";

export const queryString = new Proxy(
  new URLSearchParams(window.location.search),
  {
    get: (searchParams, prop: any) => searchParams.get(prop),
  },
);

export const amazingHelper = () => {
  return true;
};

export const amazingStoreLog = (reducer: any) => {
  return (preState: any, action: any) => {
    console.group(`_STORE_LOG_DEBUG : ${action.type}`);
    console.log("_Pre State: ", preState);
    console.log("_Action: ", action);
    const nextState = reducer(preState, action);
    console.groupEnd();
    return nextState;
  };
};

export const formatNumber = (
  value: any,
  includeDecimal: boolean = true,
  decimal: number = 2,
) => {
  if (includeDecimal) {
    value = parseFloat(value).toFixed(decimal);
  }
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  if (includeDecimal && parseInt(list[1]) > 0) {
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
  }

  return `${prefix}${result}`;
};

export const formatDate = (
  time: any,
  format: string = "YYYY-MM-DD",
  inputFormat: string = "YYYY-MM-DD HH:mm:ss",
) => {
  return moment(time, inputFormat).format(format);
};

export const buildQueryString = (
  queries: any,
  whiteList: any = [],
  bridgeSign: string = "?",
) => {
  const queryString: any = [];
  const checkWhitelist: boolean = whiteList.length > 0 ? true : false;
  Object.keys(queries).forEach((prop) => {
    if (queries[prop] !== "") {
      if (checkWhitelist) {
        if (whiteList.includes(prop)) {
          queryString.push(urlencode(prop) + "=" + urlencode(queries[prop]));
        }
      } else {
        queryString.push(urlencode(prop) + "=" + urlencode(queries[prop]));
      }
    }
  });

  return queryString.length > 0 ? `${bridgeSign}${queryString.join("&")}` : "";
};

export const parseQueryString = (queryString, whiteList: any = []) => {
  const str: string = queryString || window.location.search;
  const objURL: any = {};
  const checkWhitelist: boolean = whiteList.length > 0 ? true : false;

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    ($0, $1, $2, $3): any => {
      if (checkWhitelist) {
        if (whiteList.includes($1)) {
          objURL[$1] = urldecode($3);
        }
      } else {
        objURL[$1] = urldecode($3);
      }
    },
  );

  return objURL;
};

export const urlencode = (str: any) => {
  str = (str + "").toString();

  // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return encodeURIComponent(str)
    .replace(/!/g, "%21")
    .replace(/'/g, "%27")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\*/g, "%2A")
    .replace(/%20/g, "+");
};

export const urldecode = (str: any) => {
  return decodeURIComponent((str + "").replace(/\+/g, "%20"));
};

export const moneyFormat = (
  number,
  isShort,
  suffix,
  is_vn = true,
  is_decimals = false,
  decimals = 2,
  isBr = false,
) => {
  isShort = isShort !== undefined ? isShort : false;
  suffix = suffix !== undefined ? suffix : "VNĐ";
  let br = isBr ? "\n" : "";
  // kiểm tra số là âm hay dương => nếu số âm chuyển thành dương và gắn cờ là số âm ( cờ mặc định là số dương : isNegativeNumber = 1 )
  let isNegativeNumber = 1;
  if (number < 0) {
    number = number * -1;
    isNegativeNumber = -1;
  }
  let unit = "";
  // Tinh đơn vị và rút gọn số
  // Ví dụ: 1.320.000 => 1.320 m (1000 triêu)
  // 234.545.000 => 234 b (234 tỷ)
  if (isShort && !isNaN(number)) {
    if (number >= 1000000000) {
      number /= 1000000000.0;
      // ti : billion
      unit = is_vn ? "Tỷ" : "T";
    } else if (number >= 1000000) {
      number /= 1000000.0;
      // trieu : million
      unit = is_vn ? "Triệu" : "Tr";
    } else if (number >= 1000) {
      number /= 1000.0;
      // nghin : thousand
      unit = is_vn ? "Nghìn" : "N";
    }
  }
  // if (number > 100) {
  //     is_decimals = 0;
  // }
  number = formatNumber(number * isNegativeNumber, is_decimals, decimals);
  number = number === -0 ? "0" : number;

  return number === "0" ? number : number + " " + br + unit + " " + suffix;

  //  "thousand" - nghìn, "million" triệu và "billion" tỷ
};

export const slugify = (str: string): string => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  // Thay ký tự đĐ
  str = str.replace(/[đĐ]/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, "-");

  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, "-");

  // xóa phần dư - ở đầu & cuối
  str = str.replace(/^-+|-+$/g, "");

  // return
  return str;
};

export const checkEnableCookie = () => {
  let cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    document.cookie = "facebookadscookiehere";
    cookieEnabled = document.cookie.indexOf("facebookadscookiehere") !== -1;
  }
  return cookieEnabled;
};

export const pushDataStringFilterIds = (
  value: any,
  currentValues: any,
  valueDefault: any = "",
) => {
  if (currentValues === valueDefault) return value;
  const currentValuesArr = currentValues.split(",");
  if (!currentValuesArr.includes(value)) {
    currentValuesArr.push(value);
  }

  return currentValuesArr.join(",");
};

export const handleResponse = (response) => {
  if (response.has_error) {
    return Promise.resolve({
      data: response,
      isError: true,
    });
  } else {
    return Promise.resolve({
      data: response.data,
      isError: false,
    });
  }
};

export const handleResponseNoData = (response) => {
  if (response.has_error) {
    return Promise.resolve({
      data: response,
      isError: true,
    });
  } else {
    return Promise.resolve({
      data: response,
      isError: false,
    });
  }
};

export const getDataPostMessage = (data, key) => {
  // FORMAT DATA POST MESSAGE : e.data = {"source" : "googleshop", "message" : "message_code"}

  if (typeof data === "string" && !data.includes("webpack")) {
    const dataJson = JSON.parse(data);

    if (dataJson && dataJson?.source && dataJson?.source === key) {
      return { status: true, data: dataJson };
    }
  }

  return { status: false, data: null };
};

// ! đối với image vuông và image chữ nhật để chung 1 field là image
const CHECK_RULES_CHANNEL = {
  youtube: [
    "final_url",
    "icons",
    "link_videos",
    "titles",
    "description",
    "images",
  ],
  search: ["final_url", "titles", "description"],
  gmail: ["final_url", "icons", "titles", "description", "images"],
  discover: ["final_url", "icons", "titles", "description", "images"],
  display: [
    "final_url",
    "icons",
    "link_videos",
    "titles",
    "description",
    "images",
  ],
};

/**
 *
 * @param channel Current channel was chosen to review
 * @param values Values of form
 * @param currentErrors Objective of current Error Messages
 * * => Return array field need to check. Ex: ['final_url', 'icons'] 2 fields need to complete
 */
export const checkReviewNeedFill = (channel, values, currentErrors) => {
  const completeArr: Array<string> = [];

  for (const props in CHECK_RULES_CHANNEL) {
    // * Right channel with each type of input
    if (props === channel) {
      CHECK_RULES_CHANNEL[props].forEach((inputName, _) => {
        if (inputName === "final_url") {
          if (
            (values && !values?.final_url?.length) ||
            currentErrors.hasOwnProperty("final_url")
          ) {
            completeArr.push(i18next.t("review.final_url"));
          }
        }

        if (inputName === "icons") {
          if (
            (values && !values?.icons?.length) ||
            currentErrors.hasOwnProperty("icons")
          ) {
            completeArr.push(i18next.t("review.icon"));
          }
        }

        if (inputName === "link_videos") {
          if (
            (values && !values?.link_videos[0]?.length) ||
            currentErrors.hasOwnProperty("link_videos")
          ) {
            completeArr.push("1 video");
          }
        }

        if (inputName === "titles") {
          const noEmptyStringsLength = values?.titles.filter(
            (str) => str !== "",
          )?.length;

          if (noEmptyStringsLength < 3) {
            completeArr.push(
              i18next.t("review.n_headlines", {
                number: 3 - noEmptyStringsLength,
              }),
            );
          }
        }

        if (inputName === "description") {
          const noEmptyStringsLength = values?.description.filter(
            (str) => str !== "",
          )?.length;

          if (noEmptyStringsLength < 2) {
            completeArr.push(
              i18next.t("review.n_description", {
                number: 2 - noEmptyStringsLength,
              }),
            );
          }
        }

        if (inputName === "images") {
          if (
            values &&
            values?.images_squa?.length + values?.images_rec?.length < 2
          ) {
            completeArr.push(
              i18next.t("review.n_image", {
                number:
                  2 -
                  (values?.images_squa?.length + values?.images_rec?.length),
              }),
            );
          }
        }
      });
    }
  }

  return completeArr;
};

export const getYoutubeVideoId = (link: string) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

  const match = link.match(regExp);

  // eslint-disable-next-line eqeqeq
  return match && match[7]?.length == 11 ? match[7] : false;
};

export const isEmptyObj = (obj: Record<string, any>) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const separateItemToGroup = (arrayFilter: any, parentField) => {
  const result: any = {};

  for (let i = 0; i < arrayFilter?.length; i++) {
    const item = arrayFilter[i];

    if (!result[item[parentField]]) {
      result[item[parentField]] = [];
    }

    result[item[parentField]].push(item);
  }

  return result;
};

export const sliceStringInUrl = (str: string) => {
  if (str && str?.length) {
    const firstStr = str.slice(0, 10);
    const middleStr = "...";
    const lastStr = str.slice(-10);

    return firstStr + middleStr + lastStr;
  } else {
    return "";
  }
};

export const replaceHTMLTags = (htmlString: string) => {
  if (htmlString && htmlString.length) {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
  } else {
    return "";
  }
};

export const renewableProductsSubmit = (list, keyChild: string) => {
  const productList = list.filter((y) => !y.hasOwnProperty(keyChild));
  const variantList = list.filter((y) => y.hasOwnProperty(keyChild));

  // eslint-disable-next-line array-callback-return
  productList.map((product) => {
    product.variants = variantList.filter(
      (variant) => variant.product_id === product.id,
    );
  });

  return productList;
};

export const formatCurrency = (currency, number) => {
  const locale = {
    JPY: "ja-JP",
    USD: "en-US",
    SGD: "en-SG",
    VND: "vi-VN",
    PHP: "tl-PH",
  };
  return new Intl.NumberFormat(locale[currency], {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).format(Number(number));
};
const generateAds = (args) => {
  const { typeInput, topic, keyword, maxLength }: any = args;

  return `Viết cho tôi ${typeInput} quảng cáo cho ngành hàng ${topic} với ngữ cảnh "${keyword}" và chỉ trả về kết quả với ${maxLength} ký tự`;
};

const GptStrategies = {
  generateAds,
};

// * Xử lý việc gọi 1 Prompt GPT theo biểu mẫu:
export const applyGpt = (namePromt, ...args) => {
  return GptStrategies[namePromt](...args);
};

// ** Export input type field
export const inputType = {
  1: {
    gpt: "tiêu đề",
    trans: "title",
  },
  2: {
    gpt: "tiêu đề",
    trans: "title_long",
  },
  3: {
    gpt: "mô tả ngắn gọn",
    trans: "description",
  },
};

export function ShowUIDate(datestart, dateend) {
  let result = "";

  const ranges = {
    "Hôm nay": [moment(), moment()],
    "Hôm qua": [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "7 ngày trước": [moment().subtract(6, "days"), moment()],
    "30 ngày trước": [moment().subtract(30, "days"), moment()],
    "Tháng này": [moment().startOf("month"), moment().endOf("month")],
    "Tháng trước": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  const formatTime = (time) => moment(time).format("DD/MM/YYYY");
  const formatTimeRmYear = (time) => moment(time).format("DD/MM");

  for (const key in ranges) {
    if (
      formatTime(ranges[key][0]).includes(formatTime(datestart)) &&
      formatTime(ranges[key][1]).includes(formatTime(dateend))
    ) {
      return `${key}`;
    } else {
      if (moment(datestart).year() === moment(dateend).year()) {
        result = `${formatTimeRmYear(datestart)} - ${formatTime(dateend)}`;
      } else {
        result = `${formatTime(datestart)} - ${formatTime(dateend)}`;
      }
    }
  }

  return result;
}

/**
 *
 * @param data data from database
 */
export const convertDataChart = (data: any, defaultData: any) => {
  let result = {
    header: [],
    data: {},
  };

  const buildHeader = data?.map((y) => y.label);
  let buildBody = {};

  for (const pros in defaultData) {
    const isExist = data?.some((y) => y.hasOwnProperty(pros));

    if (isExist) {
      buildBody[pros] = {
        ...defaultData[pros],
        value: data?.map((y) => y[pros]),
      };
    }
  }

  result.header = buildHeader;
  result.data = buildBody;

  return result;
};

/**
 *
 * @param data data from database
 */
export const convertDataDonut = (data: any, defaultData: any) => {
  const result = data
    .filter((y) => y.clicks !== 0)
    .map((item) => {
      return {
        ...defaultData[item.age_range_name],
        value: item.clicks,
      };
    });

  return result;
};

/**
 * @params isLocal, isStaging, currentOrgid
 * @function : Kiểm tra xem có được truy cập trên những môi trường khác nhau hay không ?
 */
export const isAccess = (isLocal, isStaging, currentOrgid) => {
  const orgAccess = [
    "1000360818",
    "200000040794",
    "1000166817",
    "200000099549",
    "200000692705",
    "200000011608",
  ];

  if (isLocal || isStaging) {
    return true;
  } else {
    if (orgAccess.includes(currentOrgid)) {
      return true;
    } else {
      return false;
    }
  }
};

/**
 * ! Function make a list of products > variants become products + variants
 * * state change {
 * * id => variant_id
 * * additional => id of product => product_id
 * * }
 */
export const renewableProducts = (list: any, keyChild: string) => {
  // Products List
  const productList = [...list];
  // Variants List
  const variantList = productList
    .map((par) => {
      return par[keyChild]?.map((child) => {
        return { ...child, product_id: par.id };
      });
    })
    .flat();

  variantList.forEach((variant) => {
    const indexItem = productList.findIndex(
      (product) => product.id === variant?.product_id,
    );

    if (indexItem !== -1) {
      productList.splice(indexItem + 1, 0, variant);
    }
  });

  return productList;
};

// ! Chuyển HTML Entity sang string
export function decodeHTMLEntities(text) {
  var entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["#x27", "'"],
    ["#x2F", "/"],
    ["#39", "'"],
    ["#47", "/"],
    ["lt", "<"],
    ["gt", ">"],
    ["nbsp", " "],
    ["quot", '"'],
  ];

  for (var i = 0, max = entities.length; i < max; ++i)
    text = text.replace(
      new RegExp("&" + entities[i][0] + ";", "g"),
      entities[i][1],
    );

  return text;
}

export const autoFieldPromotionPriceToProducts = (fromGG, fromOmni) => {
  const result = [...fromGG];

  const returnValue = result.map((y) => {
    if (y.hasOwnProperty("product_id")) {
      const findVariantFromOmni = fromOmni.find((i) => i.id === y.id);

      if (parseInt(findVariantFromOmni.price) < y.price) {
        return {
          ...y,
          sale_price: parseInt(findVariantFromOmni.price),
        };
      } else {
        return {
          ...y,
          sale_price: 0,
        };
      }
    } else {
      return { ...y };
    }
  });

  return returnValue;
};

export const removeDuplicate = (array) => {
  let uniqArray = array.filter((c, index) => {
    return array.indexOf(c) === index;
  });

  return uniqArray;
};
