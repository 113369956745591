/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:52:02
 * @modify date 2022-10-18 09:52:02
 * @desc [description]
 */

import { LazyLoad } from "utils/Loadable";

// Lazy Import Public Page
const Home = LazyLoad({ cb: () => import("pages/Home") });
const Install = LazyLoad({ cb: () => import("pages/Install") });
const Onboarding = LazyLoad({ cb: () => import("pages/Onboarding") });
const Settings = LazyLoad({ cb: () => import("pages/Settings") });

// ! Đồng sản phẩm
const ProductsStatus = LazyLoad({ cb: () => import("pages/ProductsStatus") });

// ! PMax Campaign
const CampaignList = LazyLoad({
  cb: () => import("pages/Campaigns/type/list"),
});
const PmaxCampaignAdd = LazyLoad({
  cb: () => import("pages/Campaigns/type/pmax/PageAdd"),
});
const PmaxCampaignEdit = LazyLoad({
  cb: () => import("pages/Campaigns/type/pmax/PageEdit"),
});

// ! Smart Campaign
const SmartCampaignAdd = LazyLoad({
  cb: () => import("pages/Campaigns/type/smart/PageAdd"),
});
const SmartCampaignEdit = LazyLoad({
  cb: () => import("pages/Campaigns/type/smart/PageEdit"),
});

// ! Shopping Campaign
const ShoppingCampaignAdd = LazyLoad({
  cb: () => import("pages/Campaigns/type/shopping/PageAdd"),
});

const ShoppingCampaignEdit = LazyLoad({
  cb: () => import("pages/Campaigns/type/shopping/PageEdit"),
});

// ! Đồng bộ sản phẩm
const SyncProducts = LazyLoad({
  cb: () => import("pages/SyncProducts"),
});

// ! Đệm đẩy sản phẩm qua backend trước khi vào
const PushProduct = LazyLoad({
  cb: () => import("pages/Bulk"),
});

const Documents = LazyLoad({ cb: () => import("pages/Documents") });
const TrialRegister = LazyLoad({ cb: () => import("pages/TrialRegister") });
const InstallRejected = LazyLoad({
  cb: () => import("pages/Install/Rejected"),
});
// ! Reports V2
const ReportV2 = LazyLoad({ cb: () => import("pages/NewReport") });

// ! Prevent App
const PreventApp = LazyLoad({ cb: () => import("pages/PreventApp") });

const Notfound = LazyLoad({ cb: () => import("pages/Notfound") });
const NoLanguage = LazyLoad({
  cb: () => import("pages/NoLanguage"),
});
const NoMobile = LazyLoad({ cb: () => import("pages/NoMobile") });

export {
  Home,
  Install,
  Onboarding,
  Settings,
  CampaignList,
  PmaxCampaignAdd,
  PmaxCampaignEdit,
  ProductsStatus,
  Documents,
  TrialRegister,
  InstallRejected,
  Notfound,
  NoLanguage,
  NoMobile,
  ReportV2,
  SmartCampaignAdd,
  SmartCampaignEdit,
  ShoppingCampaignAdd,
  ShoppingCampaignEdit,
  SyncProducts,
  PushProduct,
  PreventApp,
};
