/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-08-11 16:01:30
 * @modify date 2022-08-11 16:01:30
 * @desc [description]
 */
import { useEnvironmentName } from "hooks";
import { useNavigate } from "react-router-dom";
import { useApp } from "services/embed";

// Hook
export function useNavigateOmni() {
  const navigate = useNavigate();
  const { isProduction } = useEnvironmentName();
  const { navigateOmni: navigateAppOmni } = useApp();

  const navigateOmni = (path: string) => {
    !isProduction && navigateAppOmni(`${path}`);
    navigate(path);
  };
  return navigateOmni;
}
