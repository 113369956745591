/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-08-11 16:01:20
 * @modify date 2022-08-11 16:01:20
 * @desc [description]
 */
import { ENV_LOCAL, ENV_STAGING, ENV_PRODUCTION } from "services/constants";

export const useEnvironmentName = () => {
  const env = process.env.REACT_APP_ENV ?? ENV_PRODUCTION;
  const isLocal = env === ENV_LOCAL;
  const isStaging = env === ENV_STAGING;
  const isProduction = env === ENV_PRODUCTION;

  return {
    isLocal: isLocal,
    isStaging: isStaging,
    isProduction: isProduction,
  };
};
