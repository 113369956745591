import React from "react";
import { useApp } from "services/embed";

export const useDetectPage = (title) => {
  const { shopStatus } = useApp();

  React.useEffect(() => {
    const _window: any = window;
    const getDataLayer = _window.dataLayer;
    getDataLayer.push({
      event: "page_changed",
      page_title: `${
        shopStatus?.shopName || "Empty"
      } ~ ${title} ~ Google Ads ~ Haravan`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
