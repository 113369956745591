// import { Button } from "@haravan/react-components";

import { ReactComponent as ErrorSVG } from "assets/images/error/error.svg";
import { useDetectPage } from "hooks/useDetectPage";

// const HButton: any = Button;

function ErrorPage() {
  useDetectPage("Page Error");

  // const handleReloadPage = () => {
  //   window.location.reload();
  // };

  return (
    <div className="wrapper-container-embbed">
      <div className="hrv-gga-row">
        <div className="hrv-gga-col-12">
          <div
            className="hrv-gga-mg-auto hrv-gga-mt-90"
            style={{ width: "300px", minWidth: "300px" }}
          >
            <ErrorSVG />
          </div>

          <div className="hrv-gga-mg-auto hrv-gga-text-center">
            <h4 className="hrv-gga-fs-50 hrv-gga-fw-bold hrv-gga-mt-10">
              Đã có lỗi xảy ra
            </h4>

            <p className="hrv-gga-fs-16 hrv-gga-text-color-gray-1">
              Trang bạn đang truy cập đã xảy ra lỗi
              <br /> chúng tôi sẽ tìm cách khắc phục trong thời gian sớm nhất.
            </p>

            {/* <HButton
              onClick={handleReloadPage}
              className="hrv-gga-px-30 hrv-gga-mt-10"
              status="primary"
            >
              Trở về ứng dụng Tiktok Ads
            </HButton> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
