import { Loading as HLoading } from "@haravan/react-components";

const Loading = (props) => {
  const { height } = props;
  return (
    <div
      style={{ height: `${height}px` }}
      className="hrv-gga-d-flex hrv-gga-items-center hrv-gga-justify-center hrv-gga-align-center"
    >
      <HLoading />
    </div>
  );
};

Loading.defaultProps = {
  height: 560,
};

export default Loading;
