import { Alert } from "@haravan/react-components";

interface IProps {
  title?: string;
  className?: string;
  description?: any;
}

export default function AlertGeneral(props: IProps) {
  const { title, description, className } = props;
  return (
    <div
      className={`${
        className ? className : "hrv-gga-mt-20"
      }  hrv-gga-border-status-warning `}
    >
      <Alert
        className="hrv-gga-mb-0"
        status="warning"
        description={
          <>
            <p className="hrv-gga-mb-0 hrv-gga-fs-14 hrv-gga-fw-500">
              {!!title?.length && title}
            </p>
            <p className="hrv-gga-mb-0 hrv-gga-text-color-black-5">
              {!!description && description}
            </p>
          </>
        }
        showIcon
      />
    </div>
  );
}
