import React from "react";
import Catch from "utils/ExceptionError";
import ErrorPage from "pages/ErrorPage";

type Props = {
  children: React.ReactNode;
};

export const ErrorBoundary = Catch(function ErrorBoundary(
  props: Props,
  error?: Error,
) {
  if (error) {
    console.log(error.message);

    return (
      // ! Cần design cho screen này để user đẩy vào khi bị error
      <ErrorPage />
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
});
