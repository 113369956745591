/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:51:38
 * @modify date 2022-10-19 09:51:38
 * @desc [description]
 */

const options: any = {
  client_id: process.env.REACT_APP_HARAVAN_CLIENT_ID,
  api_url: process.env.REACT_APP_API_URL ?? "https://localhost:7054",
  install_app_path: process.env.REACT_APP_INSTALL_APP_PATH ?? "/app/install",
  myharavan: process.env.REACT_APP_MY_HARAVAN ?? "myharavan.com",
  authority: process.env.REACT_APP_AUTHORITY ?? "https://accounts.haravan.com",
};

export default options;
