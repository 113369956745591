/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-08-11 16:00:30
 * @modify date 2022-08-11 16:00:30
 * @desc [description]
 */
import { useEnvironmentName } from "hooks";

const useAmazingStoreLog = () => {
  const { isLocal } = useEnvironmentName();
  const isDebugStore = process.env.REACT_APP_SHOW_DEBUG_STORE ?? "off";
  const isShow = isDebugStore === "on" && isLocal;

  const amazingStoreLog = (reducer: any) => {
    return (preState: any, action: any) => {
      isShow && console.group(`_STORE_LOG_DEBUG : ${action.type}`);
      isShow && console.log("_Pre State: ", preState);
      isShow && console.log("_Action: ", action);
      const nextState = reducer(preState, action);
      isShow && console.groupEnd();
      return nextState;
    };
  };

  return amazingStoreLog;
};

export default useAmazingStoreLog;
