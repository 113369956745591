import { useContext } from "react";
import { AppContext } from "../appContext";

const useEmbed = () => {
  const { shop, shopStatus, app, setLocale }: any = useContext(AppContext);
  return {
    shop,
    shopStatus,
    app,
    fetchJson: app?.fetchJson,
    redirectToInstallApp: app?.redirectToInstallApp,
    redirectToUrl: app?.redirectToUrl,
    push: app?.push,
    navigateOmni: app?.navigateOmni,
    setLocale,
    showMessage: app?.showMessage,
    showError: app?.showError,
    gmcHasProblem: app?.gmcHasProblem,
    history: app?.history,
    redirectToHaravanAdmin: app?.redirectToHaravanAdmin,
    fullcreateFn: app?.fullcreateFn,
    fullcreateCloseFn: app?.fullcreateCloseFn,
    showMessege: app?.showMessege,
    pilot_new_product_sync: shopStatus?.pilot_new_product_sync,
  };
};

export default useEmbed;
