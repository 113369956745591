import i18next from "i18next";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import messages from "./messages";

function ButtonSupport(props) {
  const { url, name } = props;

  const { t } = useTranslation();

  return (
    <div
      className="hrv-gga-button-support  hrv-gga-cursor-pointer hrv-gga-py-15 hrv-gga-px-24 hrv-gga-border-blue-5 hrv-gga-d-flex hrv-gga-justify-center"
      onClick={() => {
        url.length && window.open(url);
      }}
      style={{
        borderRadius: "28px",
        width: "fit-content",
        background: "#ffffff",
      }}
    >
      <span>{svgQuestion}</span>

      <p className="hrv-gga-font-secondary hrv-gga-ml-4 hrv-gga-mb-0 hrv-gga-fs-14 hrv-gga-text-color-blue-5 hrv-gga-fw-700">
        {`${i18next.t(`support_about`, {
          name: t(messages[name]()),
        })}`}
      </p>
    </div>
  );
}

export default ButtonSupport;

const svgQuestion = (
  <svg width="20" height="20">
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333374 7.00004C0.333374 3.32004 3.32004 0.333374 7.00004 0.333374C10.68 0.333374 13.6667 3.32004 13.6667 7.00004C13.6667 10.68 10.68 13.6667 7.00004 13.6667C3.32004 13.6667 0.333374 10.68 0.333374 7.00004ZM7.66671 9.66671V11H6.33337V9.66671H7.66671ZM7.00004 12.3334C4.06004 12.3334 1.66671 9.94004 1.66671 7.00004C1.66671 4.06004 4.06004 1.66671 7.00004 1.66671C9.94004 1.66671 12.3334 4.06004 12.3334 7.00004C12.3334 9.94004 9.94004 12.3334 7.00004 12.3334ZM4.33337 5.66671C4.33337 4.19337 5.52671 3.00004 7.00004 3.00004C8.47337 3.00004 9.66671 4.19337 9.66671 5.66671C9.66671 6.52199 9.14003 6.98225 8.62722 7.4304C8.14072 7.85555 7.66671 8.26979 7.66671 9.00004H6.33337C6.33337 7.78586 6.96145 7.30443 7.51367 6.88116C7.94686 6.54911 8.33337 6.25285 8.33337 5.66671C8.33337 4.93337 7.73337 4.33337 7.00004 4.33337C6.26671 4.33337 5.66671 4.93337 5.66671 5.66671H4.33337Z"
        fill="#2962FF"
      ></path>
    </svg>
  </svg>
);
