/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:48:04
 * @modify date 2022-10-18 09:48:04
 * @desc [description]
 */

import { NoLanguage, Notfound } from "pages";
import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "services/auth";
import { AppProvider } from "services/embed";
import { MasterHOC } from "services/hocs";
import { MasterLayout } from "./components/Layout";
import { ErrorBoundary } from "components/Boundary";
import { ProtectedRoutes, PublicRoutes } from "./routes";
import RoutePropagator from "RoutePropagator";

function App() {
  return (
    <div className="App hrv-gga-mb-100 hrv-gga-md-mb-0">
      <ErrorBoundary>
        <AppProvider>
          <AuthProvider>
            <RoutePropagator />

            <Routes>
              {PublicRoutes.map((route: any, index: number) => {
                const Component = route.component;
                let Layout: any = MasterLayout;

                if (route.layout) {
                  Layout = route.layout;
                } else if (null === route.layout) {
                  Layout = Fragment;
                }

                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <Layout>
                        <Component />
                      </Layout>
                    }
                  />
                );
              })}

              {ProtectedRoutes.map((route: any, index: number) => {
                const Component = route.component;
                let Layout: any = MasterLayout;

                if (route.layout) {
                  Layout = route.layout;
                } else if (null === route.layout) {
                  Layout = Fragment;
                }

                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <Layout>
                        <MasterHOC {...route}>
                          <Component />
                        </MasterHOC>
                      </Layout>
                    }
                  />
                );
              })}

              <Route path="/no-support-language" element={<NoLanguage />} />
              <Route path="*" element={<Notfound />} />
            </Routes>
          </AuthProvider>
        </AppProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
