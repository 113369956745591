/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:49:10
 * @modify date 2022-10-18 09:49:10
 * @desc [description]
 */

import { actions } from "@haravan/app-sdk";
import jwt_decode from "jwt-decode";
import semaphore from "semaphore";
import options from "secret/options";
import AppSDK from "./appsdk";
import { Ishop } from "./types";
const SessionToken = actions.SessionToken;
const Redirect = actions.Redirect;
const History = actions.History;
const Fullscreen = actions.Fullscreen;

const AUTH_ERROR = "APP::ERROR::NOT_FOUND";

const EmbbedApp = (shop: Ishop, _appRunning: boolean = false) => {
  const appSdk: any = AppSDK(shop.shop);
  const appRedirect: any = Redirect.create(appSdk);
  const history: any = History.create(appSdk);
  let jwtToken: any = null;
  const sem: any = semaphore(1);
  const appRunning: boolean = _appRunning;
  const fullscreen: any = Fullscreen.create(appSdk);

  const redirectToInstallApp = async () => {
    const orgId: string = shop.orgid;
    const origin: string = window.location.origin;
    const installUrl: string = `${origin}/install?orgid=${orgId}`;
    redirectToUrl(installUrl);
  };

  const redirectToUrl = async (url: string) => {
    appRedirect.dispatch(Redirect.Action.REMOTE, url);
  };

  const push = async (url: string) => {
    history.dispatch(History.Action.PUSH, url);
    appRedirect.dispatch(
      Redirect.Action.ADMIN_PATH,
      `/apps/${options.client_id}${url}`,
    );
  };

  const navigateOmni = (path: string) => {
    history.dispatch(History.Action.REPLACE, path);
  };

  const fetchData = async (...args: any) => {
    const __jwt: any = await fetchTokenSem();

    const bearerHeader = {
      headers: {
        Authorization: `Bearer ${__jwt?.token}`,
      },
    };

    if (args.length > 1) {
      args[1].headers = { ...args[1].headers, ...bearerHeader.headers };
    } else args = [...args, bearerHeader];

    return fetch(args[0], args[1]);
  };

  const fetchJson = async (...args: any) => {
    try {
      const response = await fetchData(...args);
      const data = response.json().catch(() => {
        return {}; // handle unexpected throw err when .json() fail
      });
      if (response.ok) {
        return data;
      }
      const error: any = new Error(response.statusText);
      error.response = response;
      error.data = data;
      throw error;
    } catch (error: any) {
      if (!error.data) {
        error.data = { message: error.message };
      }
      throw error;
    }
  };

  const fetchTokenSem = () => {
    return new Promise((resolve, reject) => {
      sem.take(() => {
        fetchToken()
          .then((data) => {
            resolve(data);
          })
          .catch(() => reject())
          .finally(() => {
            sem.leave();
          });
      });
    });
  };

  const fetchToken = async () => {
    try {
      if (jwtToken && jwtToken.expiredAt > new Date().getTime()) {
        return jwtToken;
      }

      const token: any = await SessionToken.getSessionToken(appSdk);
      const expiredAt: any = jwt_decode(token);
      jwtToken = {
        token: token,
        expiredAt: (expiredAt.exp - 60) * 1000,
      };

      return jwtToken;
    } catch (err: any) {
      if (err && AUTH_ERROR === err.type) {
        await redirectToInstallApp();
      }
      return null;
    }
  };

  const fetchAppStatus = async () => {
    const data: any = await fetchJson(`${options.api_url}/app/status`);

    return data;
  };

  // ! Call api get status GMC
  const fetchGMCStatus = async () => {
    const data: any = await fetchJson(`${options.api_url}/merchants/suspended`);

    return data;
  };

  const showMessage = (message: string, duration: number = 3000) => {
    const options = {
      message: message,
      duration: duration,
    };
    const toastNotice = actions.Toast.create(appSdk, options);
    toastNotice.dispatch(actions.Toast.Action.SHOW);
  };
  const showError = (message: string, duration: number = 3000) => {
    const options = {
      message: message,
      duration: duration,
      isError: true,
    };
    const toastNotice = actions.Toast.create(appSdk, options);
    toastNotice.dispatch(actions.Toast.Action.SHOW);
  };

  const redirectToHaravanAdmin = async (name: string) => {
    appRedirect.dispatch(Redirect.Action.ADMIN_SECTION, {
      name,
    });
  };

  const fullcreateFn = () => {
    fullscreen.dispatch(actions.Fullscreen.Action.ENTER);
  };

  const fullcreateCloseFn = () => {
    fullscreen.dispatch(actions.Fullscreen.Action.EXIT);
  };

  const showMessege = (message: string, duration: number = 3000) => {
    const options = {
      message: message,
      duration: duration,
    };
    const toastNotice = actions.Toast.create(appSdk, options);
    toastNotice.dispatch(actions.Toast.Action.SHOW);
  };

  return {
    appRunning,
    appSdk,
    fetchData,
    fetchJson,
    redirectToUrl,
    redirectToInstallApp,
    fetchAppStatus,
    fetchGMCStatus,
    showMessage,
    showError,
    push,
    navigateOmni,
    history,
    redirectToHaravanAdmin,
    fullcreateFn,
    fullcreateCloseFn,
    showMessege,
  };
};

export default EmbbedApp;
