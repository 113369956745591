/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import useWindowDimensions from "hooks/useWindowDimension";
import { NotDevicePage } from "components/NotDevicePage";

type IProps = {
  device: any;
  children: React.ReactNode;
};

export const CheckDetectDevice: React.FunctionComponent<IProps> = ({
  children,
  device,
}) => {
  const { width } = useWindowDimensions();
  const BREAK_POINT_TABLET = 769;
  const BREAK_POINT_MOBILE = 550;

  const detectDesktop = () => {
    if (BREAK_POINT_TABLET < width && device.includes("desktop")) {
      return true;
    } else {
      return false;
    }
  };

  const detectMobile = () => {
    if (BREAK_POINT_MOBILE > width && device.includes("mobile")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {detectDesktop() ? (
        <>{children}</>
      ) : detectMobile() ? (
        <>{children}</>
      ) : (
        <NotDevicePage />
      )}
    </>
  );
};
