import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { actions } from "@haravan/app-sdk";
import { useApp } from "services/embed";

const RoutePropagator = () => {
  const location = useLocation();
  const { history } = useApp();

  // ! Lắng nghe event thay đổi path của app và Cập nhật lại browser path
  useEffect(() => {
    const isIframe = window.self !== window.top;
    if (!isIframe) {
      return;
    }
    let path = location.pathname;

    const searchParams: any = new URLSearchParams(location.search);
    searchParams.delete("locale");
    searchParams.delete("timestamp");
    searchParams.delete("shop");
    searchParams.delete("hmac");
    searchParams.delete("orgid");
    if (searchParams.size > 0) {
      path += `?${searchParams.toString()}`;
    }

    history.dispatch(actions.History.Action.REPLACE, path);
  }, [location, history]);

  return <></>;
};

export default memo(RoutePropagator);
