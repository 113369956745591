/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from "react";
import { useNavigateOmni } from "hooks";
import { useAuth } from "services/auth";

const RequireAuth = memo((props: any) => {
  const navigateOmni = useNavigateOmni();
  const { stateAuth } = useAuth();

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, [stateAuth]);
  };

  useEffectDidMount((): void => {
    // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
    // if (!stateAuth.isEnableShopAccess) {
    //   return navigateOmni("/register-trial");
    // } else if (!stateAuth.isOnBoardingCompleted) {
    //   return navigateOmni("/onboarding");
    // }

    if (!stateAuth.isOnBoardingCompleted) {
      return navigateOmni("/onboarding");
    }
  });

  const isReady = () => {
    // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
    // return stateAuth.isOnBoardingCompleted && stateAuth.isEnableShopAccess; 
    return stateAuth.isOnBoardingCompleted;
  };

  return isReady() ? <>{props.children}</> : null;
});

export default RequireAuth;
