import { Ishop, Iapp, IshopStatus } from "./types";

export const DEFAULT_SHOP_DATA: Ishop = {
  pageInit: false,
  shop: "",
  locale: "",
  orgid: "",
  isIframe: false,
};

export const DEFAULT_APP_DATA: Iapp = {
  appRunning: false,
};

export const DEFAULT_SHOP_STATUS_DATA: IshopStatus = {
  init: false,
  orgId: "",
  // isEnableShopAccess: false,  // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
  isOnBoardingCompleted: false,
  onBoardingStep: 0,
  planName: "",
  planStatus: "",
  shopName: "",
  pilot_new_product_sync: false,
};
