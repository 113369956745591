/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */

import { translations } from "locales/translations";
import { _t } from "locales/messages";

const main = translations;

const messages = {
  Campaign_management: () => _t(main.Campaign_management),
  Settings: () => _t(main.Settings),
  Sync_products: () => _t(main.Sync_products),
};

export default messages;
