import loyaltyDevice from "assets/images/nodevice/loyalty_device.svg";
import "./styles.scss";

export function NotDevicePage() {
  return (
    <div className="device-container">
      <img
        src={loyaltyDevice}
        style={{ maxWidth: "260px", textAlign: "center" }}
        alt="no-supports"
      />
      <p style={{ fontWeight: "bold", textAlign: "center", fontSize: 18 }}>
        Ứng dụng hiện chỉ hỗ trợ trên <br />
        thiết bị máy vi tính.
      </p>
      <p
        style={{
          margin: 0,
          textAlign: "center",
          color: "gray",
          fontSize: 14,
        }}
      >
        Phiên bản điện thoại sẽ được cập nhật vào thời gian tới!
      </p>
    </div>
  );
}
