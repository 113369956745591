/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:49:59
 * @modify date 2022-10-18 09:49:59
 * @desc [description]
 */

import { createApp } from "@haravan/app-sdk";
import options from "secret/options";

let app: any = null;

const AppSdk = (domain: string) => {
  if (app == null) {
    const appConfig = {
      apiKey: `${options.client_id}`,
      shopOrigin: domain,
      embedded: true,
    };

    app = createApp(appConfig);
  }

  return app;
};

export default AppSdk;
