/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:49:44
 * @modify date 2022-10-18 09:49:44
 * @desc [description]
 */

/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "components/Loading";
import {
  // useEnvironmentName,
  useLocalStorage
} from "hooks";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { goToEmbedApp, goToInstallApp } from "./hooks";
import { queryString } from "../helper";
import EmbbedApp from "./app";
import {
  DEFAULT_APP_DATA,
  DEFAULT_SHOP_DATA,
  DEFAULT_SHOP_STATUS_DATA,
} from "./constants";
import { Iapp, IrootAppState, Ishop, IshopStatus } from "./types";
// import { checkAccess } from "services/shop/checkAccess";
export const AppContext = createContext({});

export const AppProvider = (props: any) => {
  const [shop, setShop] = useState<Ishop>(DEFAULT_SHOP_DATA);
  const [shopStatus, setShopStatus] = useState<IshopStatus>(
    DEFAULT_SHOP_STATUS_DATA,
  );
  const [app, setApp] = useState<Iapp>(DEFAULT_APP_DATA);
  const { i18n } = useTranslation<any>();
  const location: any = useLocation();
  const { getItem, setItem, removeItem } = useLocalStorage();
  const storageKey = "hrv_locale";

  // const { isProduction } = useEnvironmentName();

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, []);
  };

  useEffectDidMount(() => {
    const _queryString: any = queryString;
    const shopData: Ishop = {
      pageInit: true,
      shop: _queryString.shop,
      locale: getLocale(_queryString),
      orgid: _queryString.orgid,
      isIframe: window.self !== window.top,
    };

    // Gán các tham số cho TRACKING
    var _document: any = document;
    _document.org_id = shopData.orgid;
    _document.locale = shopData.locale;
    _document.seller_domain = `https://${shopData.shop}/admin`;

    setShop(shopData);
  });

  const useEffectCheckGoToEmbedAppOrGoToInstallApp = (
    effect: React.EffectCallback,
  ) => {
    useEffect(effect, [shop, location.pathname]);
  };

  useEffectCheckGoToEmbedAppOrGoToInstallApp(() => {
    if (shop.pageInit && !shop.isIframe) {
      // go to embed app
      if (location.pathname !== "/install") {
        goToEmbedApp();
      } else {
        goToInstallApp();
      }
      return;
    }
  });

  const useEffectSetEmbedApp = (effect: React.EffectCallback) => {
    useEffect(effect, [shop]);
  };

  useEffectSetEmbedApp(() => {
    if (shop.pageInit && shop.isIframe && shop.shop) {
      const apprunning: boolean = true;
      const app: Iapp = EmbbedApp(shop, apprunning);

      setApp(app);
    }
  });

  const useEffectFetchAppStatus = (effect: React.EffectCallback) => {
    useEffect(effect, [app.appRunning]);
  };

  useEffectFetchAppStatus(() => {
    if (app && app !== undefined && app.appRunning && !shopStatus.init) {
      const fetchAppStatus = async () => {
        const data = await app.fetchAppStatus();
        const gmc = await app.fetchGMCStatus();

        if (data.need_to_install_app) {
          return app.redirectToInstallApp();
        }

        const shopStatus: IshopStatus = {
          init: true,
          orgId: data.org_id,
          // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
          // isEnableShopAccess:
          //   isProduction && checkAccess() ? true : data.is_enable_shop_access,
          isOnBoardingCompleted: data.is_on_boarding_completed,
          onBoardingStep: data.on_boarding_step,
          planName: data.plan_name,
          planStatus: data.plan_status,
          gmcHasProblem: !gmc.has_error ? (gmc.data <= 7 ? gmc.data : 0) : 0,
          shopName: data.shop_name,
          pilot_new_product_sync: data.pilot_new_product_sync,
        };

        // Gán các tham số cho TRACKING
        var _document: any = document;
        _document.plan_name = shopStatus.planName;
        _document.plan_status = shopStatus.planStatus;
        _document.gmc_has_problem = shopStatus.gmcHasProblem;

        setShopStatus(shopStatus);
      };
      fetchAppStatus();
    }
  });

  const useEffectChangeLanguage = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [shop.locale]);
  };

  useEffectChangeLanguage(() => {
    if (shop && shop.locale !== "") {
      i18n.changeLanguage(shop.locale);
    }
  });

  const setLocale = (locale: string) => {
    setItem(storageKey, locale);
    setShop((currentState) => ({ ...currentState, locale: locale }));
  };

  const getLocale = (_queryString: any): string => {
    const locale: any = getItem(storageKey);
    const queryLocale = _queryString.locale;
    queryLocale === "vi" && removeItem(storageKey);
    return locale ? locale : queryLocale;
  };

  const isReady = (): boolean => {
    // kiểm tra dữ liệu shop đã được set
    // kiểm tra app đã được running
    return shop && shop.pageInit && app && app.appRunning && shopStatus.init;
  };

  const rootAppState: IrootAppState = {
    shop,
    shopStatus,
    app,
    setLocale,
  };

  return (
    <>
      {isReady() ? (
        <AppContext.Provider value={rootAppState}>
          {props.children}
        </AppContext.Provider>
      ) : (
        <Loading />
      )}
    </>
  );
};
