import { memo } from "react";
import { CheckDetectDevice } from "./CheckDetectDevice";
import RequireAuth from "./requireAuth";

import AlertGeneral from "components/AlertGeneral";
import { useEmbed } from "services/embed/hooks";
import { useTranslation } from "react-i18next";
import { translations } from "locales/translations";
import { _t } from "locales/messages";

import ButtonSupport from "components/ButtonSupport";

type IProps = {
  children: React.ReactNode;
  rest?: any;
};

const messages = {
  title: () => _t(translations.warning),
  description: () => _t(translations.warning_description),
};

const MasterHOC = ({ children, ...rest }: IProps) => {
  const { device, showGMCHasProblem, showSupportButton, name, url }: any = rest;
  const { shopStatus } = useEmbed();
  const { t } = useTranslation();

  return (
    <RequireAuth>
      {showGMCHasProblem && !!shopStatus.gmcHasProblem && (
        <AlertGeneral
          title={t(messages.title())}
          description={t(messages.description(), {
            days: `0${shopStatus.gmcHasProblem}`,
          })}
        />
      )}

      <CheckDetectDevice device={device}>{children}</CheckDetectDevice>

      {showSupportButton && (
        <div className="hrv-gga-d-flex hrv-gga-justify-center hrv-gga-my-52">
          <ButtonSupport name={name} url={url} />
        </div>
      )}
    </RequireAuth>
  );
};

export default memo(MasterHOC);
