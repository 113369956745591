/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo } from "react";

import Context from "./Context";
import { useApp } from "services/embed";
import { IstateAuth } from "./types";
import {
  queryString,
  getDataPostMessage,
  buildQueryString,
} from "services/helper";
import options from "secret/options";
import {
  AUTH_STATE_DEFAULT,
  CONNECT_SETTING_MERCHANT,
  POST_MESSAGE_SOURCE_GOOGLE_SHOP,
} from "./constants";

const initialAuthState: IstateAuth = AUTH_STATE_DEFAULT;

const Provider = memo((props: any) => {
  const { shopStatus, showError } = useApp();
  const [stateAuth, setStateAuth] = useState<IstateAuth>(initialAuthState);

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, []);
  };

  useEffectDidMount(() => {
    if (!stateAuth.ready) {
      checkLogin();
    }

    const eventName = "message";
    window.addEventListener(eventName, handleGoogleLoginCallback, false);

    return () => {
      window.removeEventListener(eventName, handleGoogleLoginCallback, false);
    };
  });

  const handleGoogleLoginCallback = (event: any) => {
    if (event.data) {
      // Get data post message form google shop
      const { status, data } = getDataPostMessage(
        event.data,
        POST_MESSAGE_SOURCE_GOOGLE_SHOP,
      );
      if (status) {
        switch (data?.message) {
          case "google_login_success":
            setOnboardingStep(CONNECT_SETTING_MERCHANT);
            break;

          case "google_login_new_version_success":
            setRelogin(true);
            break;

          case "errors.client.you_need_to_login_with_email_connected_to_haravan":
            showError(
              "Bạn cần đăng nhập bằng tài khoản email đã kết nối với Haravan.",
            );

            break;

          case "pending_delete_products_from_google_merchant_center":
            showError(
              "Đang trong quá trình xử lý xóa sản phẩm khỏi Google Merchant Center. Vui lòng thử lại sau",
            );
            break;
          case "limit_5_adaccount":
            showError(
              "Tài khoản Google của bạn hiện đang được gắn với tối đa số tài khoản Google Ads cho phép (5 tài khoản).",
            );
            break;
          default:
            showError(`Error: ${data?.message}`);
        }
      }
    }
  };

  const checkLogin = () => {
    setActived(shopStatus.isActive);
    setOnboardingCompleted(shopStatus.isOnBoardingCompleted);
    setOnboardingStep(shopStatus.onBoardingStep);
    // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
    // setIsEnableShopAccess(shopStatus.isEnableShopAccess); 
    setPlanStatus(shopStatus.planStatus);
    setReady(true);
  };

  const handleLogin = (params: any = {}) => {
    const _queryString: any = queryString;
    const width = 600;
    const height = 800;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const withParams: any = buildQueryString({
      ...params,
      orgid: _queryString.orgid,
    });
    window.open(
      `${options.api_url}/api/googles/login${withParams}`,
      "popUpWindow",
      `height=${height},width=${width},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`,
    );
  };

  const login = () => {
    handleLogin();
  };

  const changeAccount = () => {
    handleLogin({ ischange: 1 });
  };

  const getNewTokenFromLogin = () => {
    handleLogin({ newVersion: 1 });
  };

  const setReady = (payload: boolean) => {
    setStateAuth((preState) => ({ ...preState, ready: payload }));
  };

  const setOnboardingCompleted = (payload: boolean) => {
    setStateAuth((preState) => ({
      ...preState,
      isOnBoardingCompleted: payload,
    }));
  };

  const setOnboardingStep = (payload: number) => {
    setStateAuth((preState) => ({
      ...preState,
      onBoardingStep: payload,
    }));
  };

  const setRelogin = (payload: boolean) => {
    setStateAuth((preState) => ({
      ...preState,
      relogin: payload,
    }));
  };

  const setPlanStatus = (payload: string) => {
    setStateAuth((preState) => ({ ...preState, planStatus: payload }));
  };

  // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
  // const setIsEnableShopAccess = (payload: boolean) => {
  //   setStateAuth((preState) => ({ ...preState, isEnableShopAccess: payload }));
  // };

  const setActived = (payload: boolean) => {
    setStateAuth((preState) => ({
      ...preState,
      isActive: payload,
    }));
  };

  const ready = () => {
    return stateAuth.ready;
  };

  return (
    <>
      {ready() && (
        <Context.Provider
          value={{
            login,
            changeAccount,
            getNewTokenFromLogin,
            setOnboardingCompleted,
            setOnboardingStep,
            stateAuth,
          }}
        >
          {props.children}
        </Context.Provider>
      )}
    </>
  );
});

export default Provider;
