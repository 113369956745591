export const AUTH_STATE_DEFAULT = {
  ready: false,
  isActive: false,
  // isEnableShopAccess: false, // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
  isOnBoardingCompleted: false,
  onBoardingStep: 0,
  planStatus: "",
  relogin: false,
};

export const CONNECT_SETTING_MERCHANT = 5;
export const POST_MESSAGE_SOURCE_GOOGLE_SHOP = "googleshop";
