/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-08-11 16:03:59
 * @modify date 2022-08-11 16:03:59
 * @desc [description]
 */
import { LazyLoad } from "utils/Loadable";

const MasterLayout = LazyLoad({
  cb: () => import("./Master"),
});

export { MasterLayout };
