/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:51:47
 * @modify date 2022-10-18 09:51:47
 * @desc [description]
 */
import {
  // CampaignList,
  Install,
  InstallRejected,
  Onboarding,
  Settings,
  // ReportV2,
  ProductsStatus,
  Documents,
  // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
  // TrialRegister,
  // PmaxCampaignAdd,
  // PmaxCampaignEdit,
  // SmartCampaignEdit,
  // SmartCampaignAdd,
  // ShoppingCampaignAdd,
  // ShoppingCampaignEdit,
  SyncProducts,
  PushProduct,
  PreventApp,
} from "pages";

import { DESKTOP, MOBILE } from "./constant";

const PublicRoutes: any = [
  // ! Tạm ngưng không kiểm tra khách hàng cần register [3D234F2]
  // {
  //   path: "/register-trial",
  //   component: TrialRegister,
  //   device: [DESKTOP],
  // },
  {
    path: "/onboarding",
    component: Onboarding,
    device: [DESKTOP, MOBILE],
  },
  {
    path: "/install/rejected",
    component: InstallRejected,
    device: [DESKTOP],
  },
  {
    path: "/install",
    component: Install,
    device: [DESKTOP],
  },
];

const ProtectedRoutes: any = [
  {
    path: "/",
    // component: CampaignList,
    component: ProductsStatus,
    device: [DESKTOP],
    showGMCHasProblem: true,
    showSupportButton: false,
    // url: "https://support.haravan.com/support/solutions/articles/42000038953-h%C6%B0%E1%BB%9Bng-d%E1%BA%ABn-c%C3%A0i-%C4%91%E1%BA%B7t-google-performance-max-t%E1%BA%A1i-website-haravan",
    // name: "Campaign_management",
  },
  {
    path: "/campaignsmart/:id/edit",
    component: PreventApp,
    // component: SmartCampaignEdit,
    device: [DESKTOP],
    showGMCHasProblem: true,
  },
  {
    path: "/campaignsmart",
    component: PreventApp,
    // component: CampaignList,
    device: [DESKTOP],
    showGMCHasProblem: true,
    showSupportButton: false,
    // url: "https://support.haravan.com/support/solutions/articles/42000038953-h%C6%B0%E1%BB%9Bng-d%E1%BA%ABn-c%C3%A0i-%C4%91%E1%BA%B7t-google-performance-max-t%E1%BA%A1i-website-haravan",
    // name: "Campaign_management",
  },
  {
    path: "/campaignsmart/create",
    // component: SmartCampaignAdd,
    component: PreventApp,
    device: [DESKTOP],
    showGMCHasProblem: true,
  },
  {
    path: "/campaigns",
    component: PreventApp,
    device: [DESKTOP],
    showGMCHasProblem: true,
    showSupportButton: false,
    // url: "https://support.haravan.com/support/solutions/articles/42000038953-h%C6%B0%E1%BB%9Bng-d%E1%BA%ABn-c%C3%A0i-%C4%91%E1%BA%B7t-google-performance-max-t%E1%BA%A1i-website-haravan",
    // name: "Campaign_management",
  },
  // {
  //   path: "/smart/:id/edit",
  //   component: SmartCampaignEdit,
  //   device: [DESKTOP],
  //   showGMCHasProblem: true,
  // },
  // {
  //   path: "/smart/create",
  //   component: SmartCampaignAdd,
  //   device: [DESKTOP],
  //   showGMCHasProblem: true,
  // },
  {
    path: "/pmax/:id/edit",
    // component: PmaxCampaignEdit,
    component: PreventApp,
    device: [DESKTOP],
    showGMCHasProblem: true,
  },
  {
    path: "/pmax/create",
    // component: PmaxCampaignAdd,
    component: PreventApp,
    device: [DESKTOP],
    showGMCHasProblem: true,
  },
  {
    path: "/shopping/create",
    // component: ShoppingCampaignAdd,
    component: PreventApp,
    device: [DESKTOP],
    showGMCHasProblem: true,
  },
  {
    path: "/shopping/:id/edit",
    component: PreventApp,
    // component: ShoppingCampaignEdit,
    device: [DESKTOP],
    showGMCHasProblem: true,
  },
  {
    path: "/settings",
    component: Settings,
    device: [DESKTOP],
    showGMCHasProblem: true,
    showSupportButton: true,
    url: "https://support.haravan.com/support/solutions/articles/42000101348-c%C3%A1ch-t%E1%BA%A1o-l%E1%BA%A1i-t%C3%A0i-kho%E1%BA%A3n-google-m%E1%BB%9Bi-t%E1%BB%AB-haravan",
    name: "Settings",
  },
  {
    path: "/reports",
    // component: ReportV2,
    component: PreventApp,
    device: [DESKTOP],
    showGMCHasProblem: true,
  },

  {
    path: "/products",
    component: ProductsStatus,
    device: [DESKTOP],
    showGMCHasProblem: true,
    showSupportButton: true,
    url: "https://support.haravan.com/support/solutions/articles/42000070153-c%C3%A1ch-%C4%91%E1%BB%93ng-b%E1%BB%99-th%C3%AAm-x%C3%B3a-s%E1%BA%A3n-ph%E1%BA%A9m",
    name: "Sync_products",
  },
  {
    path: "/products/bulk",
    component: PushProduct,
    device: [DESKTOP],
    showGMCHasProblem: false,
  },
  {
    path: "/syncproducts/:id",
    component: SyncProducts,
    device: [DESKTOP],
    showGMCHasProblem: false,
    layout: null,
  },
  {
    path: "/documents",
    component: Documents,
    device: [DESKTOP],
    showGMCHasProblem: false,
  },
];

export { PublicRoutes, ProtectedRoutes };
