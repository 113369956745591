/**
 * @author Trung Bui - HaraLoyalty
 * @email trung.builehoai@haravan.com
 * @create date 2022-10-18 09:51:24
 * @modify date 2022-10-18 09:51:24
 * @desc [description]
 */

import options from "secret/options";
import { queryString } from "services/helper";

const goToInstallApp = () => {
  const _queryString: any = queryString;
  const url = `${options.api_url}${options.install_app_path}?orgid=${_queryString.orgid}`;
  window.location.href = url;
};

const goToEmbedApp = () => {
  let appUrl = `/admin/apps/${options.client_id}`;
  let url = `https://${
    options.myharavan
  }/admin/account/login?returnUrl=${encodeURIComponent(appUrl)}`;
  window.location.href = url;
};

export { goToInstallApp, goToEmbedApp };
